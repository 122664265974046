import { AxiosInstance } from 'axios'

import { AdUnitsService } from './services/AdUnitsService'
import { AudienceService } from './services/AudienceService'
import { PlacementsService } from './services/PlacementsService'

export class MonetizationLegacyApi {
  public readonly adUnits: AdUnitsService
  public readonly audience: AudienceService
  public readonly placements: PlacementsService

  constructor(public readonly request: AxiosInstance) {
    this.adUnits = new AdUnitsService(request)
    this.audience = new AudienceService(request)
    this.placements = new PlacementsService(request)
  }
}
