export enum DimensionENUM {
  last7days = 'I',
  last30days = 'K',
  last90days = 'L',
  day = 'D',
  week = 'W',
  month = 'M',
  quarter = 'Q',
  year = 'Y',
  allTime = 'A',
}
