import { createInjectionState } from '@lasso/shared/utils'
import { AxiosInstance } from 'axios'

import { bindApiMethods } from '@lasso/api-shared'

import { useApiCache } from '@lasso/shared/hooks'

import { MonetizationLegacyApi } from './MonetizationLegacyApi'

export const [useProvideMonetizationLegacyApi, useMonetizationLegacyApi] = createInjectionState(
  (axiosInstance: AxiosInstance) => {
    const [api, methodsMapping] = bindApiMethods(new MonetizationLegacyApi(axiosInstance))

    useApiCache().aliasCacheKeys(methodsMapping)

    return api
  },
  { throwWhenNotProvided: 'Monetization Legacy API was not provided' },
)
