import { ApiService } from '@lasso/api-shared'

import {
  PlacementsCreateResponse,
  PlacementsCreateUpdateRequest,
  PlacementsDeleteResponse,
  PlacementsGetCsvResponse,
  PlacementsGetManyCsvRequest,
  PlacementsGetManyCsvResponse,
  PlacementsListItem,
  PlacementsListRequest,
  PlacementsListResponse,
  PlacementsListShortResponse,
  PlacementsUpdateResponse,
} from '../types'

export class PlacementsService extends ApiService {
  async getPlacementData(id: number): Promise<PlacementsListItem> {
    return (await this.request({
      url: `api/monetization/adtags/placement/${id}`,
      method: 'GET',
    })).data
  }

  async getPlacementsData(data: PlacementsListRequest): Promise<PlacementsListResponse> {
    return (await this.request({
      url: 'api/monetization/adtags/placements',
      method: 'POST',
      data,
    })).data
  }

  async getPlacementsDataShort({ publisherId, marketerId, page, size }: {
    publisherId: number
    marketerId: number
    page: number
    size: number
  }): Promise<PlacementsListShortResponse> {
    const query = new URLSearchParams({
      publisher: String(publisherId),
      marketer: String(marketerId),
      page: String(page),
      size: String(size),
    })

    return (await this.request({
      url: `api/monetization/adtags/placements?${query.toString()}`,
      method: 'GET',
    })).data
  }

  async deletePlacement(id: number): Promise<PlacementsDeleteResponse> {
    return (await this.request({
      url: `api/monetization/adtags/placement/${id}`,
      method: 'DELETE',
    })).data
  }

  async createPlacement(data: PlacementsCreateUpdateRequest): Promise<PlacementsCreateResponse> {
    return (await this.request({
      url: 'api/monetization/adtags/placement',
      method: 'POST',
      data,
    })).data
  }

  async updatePlacement(id: number, data: PlacementsCreateUpdateRequest): Promise<PlacementsUpdateResponse> {
    return (await this.request({
      url: `api/monetization/adtags/placement/${id}`,
      method: 'POST',
      data,
    })).data
  }

  async getPlacementCsv(id: number): Promise<PlacementsGetCsvResponse> {
    return (await this.request({
      url: `api/monetization/adtags/placement/${id}/csv`,
      method: 'GET',
    })).data
  }

  async getPlacementManyCsv(data: PlacementsGetManyCsvRequest): Promise<PlacementsGetManyCsvResponse> {
    return (await this.request({
      url: 'api/monetization/adtags/placement/many/csv',
      method: 'POST',
      data,
    })).data
  }
}
