export type AudienceTargetableRequest = {
  filter: {
    marketerId?: number
    accountId?: number
    audienceType?: AudienceType
    keyword?: string
  }
  pager: {
    pageSize: number
    pageNumber: number
  }
}

export enum AudienceType {
  Prescriber = 'Prescriber',
  Custom = 'Custom',
}

export type AudienceTargetableItem = {
  id: number
  name: string
  type?: AudienceType | null
  children?: AudienceTargetableItem[] | null
}

export type AudienceTargetableResponse = {
  list: AudienceTargetableItem[]
  pageNumber: number
  pageSize: number
  total: number
}

export type AudienceCreateFromFileRequest = {
  name: string
  marketer: {
    id: number
  }
  source: {
    id: number
  }
  target: {
    id: number
  }
  advertiser?: {
    id: number
  }
  activeChannels: number[]
  useUid2: boolean
  isPldEnabled: boolean
  originalFileName: string
  headersJSON: string
  dataFileUrl: string
}

export type AudienceCreateFromFileResponse = {
  id: number
  name: string
}

export type AudienceUploadFileRequest = {
  filename: string
  originalFileName: string
  headers: Array<string | null>
  headersJSON: string
  filePreviewJSON: string
}
