import { objEntries, objFromEntries } from '@lasso/shared/utils'

import { SupplyMediaType } from '../../generated'

export enum SupplyMediaTypeENUM {
  BANNER = 1,
  VIDEO = 2,
  AUDIO = 3,
  ENEWSLETTER = 4,
}

export const supplyMediaTypeToLegacyMediaType: Record<SupplyMediaType, SupplyMediaTypeENUM> = {
  [SupplyMediaType.BANNER]: SupplyMediaTypeENUM.BANNER,
  [SupplyMediaType.VIDEO]: SupplyMediaTypeENUM.VIDEO,
  [SupplyMediaType.AUDIO]: SupplyMediaTypeENUM.AUDIO,
  [SupplyMediaType.E_NEWS]: SupplyMediaTypeENUM.ENEWSLETTER,
}

export const legacyMediaTypeToSupplyMediaType: Record<SupplyMediaTypeENUM, SupplyMediaType> = objFromEntries(
  objEntries(supplyMediaTypeToLegacyMediaType).map(([a, b]) => [b, a]),
)
