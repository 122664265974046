import { ApiService } from '@lasso/api-shared'

import {
  AudienceCreateFromFileRequest, AudienceCreateFromFileResponse,
  AudienceTargetableRequest,
  AudienceTargetableResponse, AudienceUploadFileRequest,
} from '../types'

export class AudienceService extends ApiService {
  async getTargetableAudiences(data: AudienceTargetableRequest): Promise<AudienceTargetableResponse> {
    return (await this.request({
      url: 'api/audience/targetable',
      method: 'POST',
      data,
    })).data
  }

  async uploadAudiencesFile(file: File): Promise<AudienceUploadFileRequest> {
    const formData = new FormData()
    formData.set('file', file)

    return (await this.request({
      url: 'api/audience/fileupload',
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      data: formData,
    })).data
  }

  async createAudienceFromFile(data: AudienceCreateFromFileRequest): Promise<AudienceCreateFromFileResponse> {
    return (await this.request({
      url: 'api/audience/edit',
      method: 'POST',
      data,
    })).data
  }
}
